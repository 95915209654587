import DateTools from '@/tools/date';
import * as RecordAPI from '@/apis/record';

// initial state
const state = {
  list_sort: [
    { value: '-id', text: 'id 降序' },
    { value: 'id', text: 'id' },

    { value: '-record_time', text: '記錄時間 降序' },
    { value: 'record_time', text: '記錄時間' },

    { value: '-updated_at', text: '更新時間 降序' },
    { value: 'updated_at', text: '更新時間' },
  ],

  list_sources: {
    indeedee: { name: '愛管侍' },
    'indeedee-backend': { name: '愛管侍-後端' },
    rotom: { name: '洛托姆' },
    turtwig: { name: '草苗龜' },
    'turtwig-plus': { name: '草苗加' },
    grotle: { name: '樹林龜' },
  },

  list_actions: [
    { text: '製造', value: 'manufacture', description: '準備開始製造。狀態將切為：備料' },
    {
      text: '組裝完成',
      value: 'assemble',
      description: '組裝完成或收到訂購的設備。狀態將切為：待測試',
    },
    { text: '維修完成', value: 'fix', description: '阿龜維修或收到亞力維修完成送回。狀態將切為：待測試' },
    { text: '入庫', value: 'ready', description: '完成功能測試進行入庫。狀態將切為：在庫' },
    {
      text: '整新入庫',
      value: 'reborn',
      description: '維修後完成功能測試整新入庫。設備將新增「整新」標記，狀態將切為：在庫',
    },
    { text: '歸還打包', value: 'giveback', description: '維修後完成功能測試歸還給客戶。狀態將切為：待運送' },
    { text: '撿貨', value: 'picking', description: '出貨前撿取設備進行整理。狀態將切為：理貨中' },
    {
      text: '打包',
      value: 'package',
      description: '將要帶去現場/要寄出的設備打包等待運送。狀態將切為：待運送',
    },
    {
      text: '出貨',
      value: 'ship',
      description: '運送或寄送至案場，記錄寄送貨運資訊（單號）。狀態將切為：已出貨',
    },
    { text: '啟用', value: 'activate', description: '啟用設備。狀態將切換為：運作中' },
    {
      text: '回報',
      value: 'report',
      description: '客戶回報問題。設備將新增「注意」標記，狀態將切換為：調查中',
    },
    {
      text: '壞掉',
      value: 'broken',
      description: '調查後判定設備已經故障。狀態將切換為：故障了',
    },
    { text: '帶回', value: 'takeback', description: '現場回收項目紀錄。狀態將切換為：檢測中' },
    { text: '需寄回', value: 'sendback', description: '確定要寄回。狀態將切為：待聯繫' },
    {
      text: '聯繫收件',
      value: 'recall',
      description: '聯繫案場人員將設備寄回，設備狀態將切為：待回收',
    },
    {
      text: '收回',
      value: 'withdraw',
      description: '記錄及檢查收到的寄回項目並紀錄。狀態將切為：檢測中',
    },
    {
      text: '需送修',
      value: 'pushqueue',
      description: '檢測後判定需要維修，將設備排入待維修清單。狀態將切為：待維修',
    },
    {
      text: '維修',
      value: 'repair',
      description: '送出維修或準備阿龜維修的紀錄。狀態切為：維修中',
    },
    { text: '現場維修', value: 'hotfix', description: '現場維修狀況紀錄。狀態切為：運作中' },
    { text: '擱置', value: 'pend', description: '設備暫時不使用，將設備的狀態切為：休息' },
    { text: '廢棄', value: 'terminate', description: '設備廢棄紀錄，將設備狀態切為：棄置' },

    { text: '注意', value: 'warn', description: '發現設備狀況需要關注事項' },
    { text: '升級紀錄', value: 'upgrade', description: '升級程式或部件紀錄' },
    { text: '一般紀錄', value: 'record', description: '調查、檢測、維修等過程中的各種紀錄' },
    { text: '測試記錄', value: 'functest', description: '進行測試時的過程及內容紀錄，狀態不改變' },
    { text: '其他', value: 'other', description: '無法歸類的動作' },
  ],

  list_common_actions: ['warn', 'upgrade', 'record', 'functest', 'other'],

  mapping_action_to_status: [
    ['manufacture', 'planning'],
    ['assemble', 'wait4test'],
    ['fix', 'wait4test'],
    ['ready', 'inventory'],
    ['reborn', 'inventory'],
    ['giveback', 'tosend'],
    ['picking', 'tally'],
    ['package', 'tosend'],
    ['ship', 'shipping'],
    ['activate', 'active'],
    ['report', 'investigating'],
    ['broken', 'malfunction'],
    ['takeback', 'checking'],
    ['sendback', 'waitcontact'],
    ['recall', 'waitback'],
    ['withdraw', 'checking'],
    ['pushqueue', 'inqueue'],
    ['repair', 'repairing'],
    ['hotfix', 'active'],
    ['pend', 'resting'],
    ['terminate', 'abandon'],
  ],
  record_sample: {
    common: [
      { title: '原因', text: '原因： [ ]。' },
      { title: '備註', text: '備註： [ ]。' },
      { title: '7pupu', text: '吼~ 氣氣777！' },
      { title: '未勾選符號', text: '☐ ' },
      { title: '已勾選符號', text: '☑ ' },
    ],
    manufacture: [
      { title: '預備製造', text: '已確定進行製造，事先安排設備號碼。' },
      { title: '組裝中', text: '組裝項目： [ ]。' },
      { title: '缺料等待', text: '缺料項目： [ ]。' },
    ],
    assemble: [
      { title: '組裝完成', text: '組裝完成，等待測試。' },
      { title: '亞力到貨', text: '亞力訂購到貨，等待入庫前測試。' },
    ],
    fix: [{ title: '維修完成', text: '維修完成，等待功能測試。' }],
    ready: [
      { title: '檢查完成', text: '檢查完成進行入庫。' },
      {
        title: 'LoRa 傳輸單元',
        text: '入庫測試檢查：\n☐☑ 外觀及鎖孔正常\n☐☑ 充電孔正常\n☐☑ 接口白點正確\n☐☑ LoRa 上傳正常\n☐☑ 料件無脫落異音',
      },
      {
        title: 'CatM 傳輸單元',
        text: '入庫測試檢查：\n☐☑ 外觀及鎖孔正常\n☐☑ 充電孔正常\n☐☑ 接口白點正確\n☐☑ CatM 上傳正常\n☐☑ 料件無脫落異音',
      },
      { title: '路由器', text: '入庫測試檢查：\n☐☑ 外觀正常\n☐☑ 配件完整\n☐☑ 開機正常\n☐☑ 4G LTE 連線正常' },
      {
        title: '閘道器',
        text: '入庫測試檢查：\n☐☑ 系統燒錄完成\n☐☑ Hostname 設定完成\n☐☑ 有線網路連線正常\n☐☑ 無線網路連線正常\n☐☑ LoRa 正常啓動',
      },
    ],
    reborn: [{ title: '整新入庫', text: '維修 & 檢測後，轉生為整新品入庫。' }],
    giveback: [{ title: '歸還', text: '維修後檢測完成，預計歸還給 [ 案場 ] 使用。' }],
    picking: [{ title: '撿貨', text: '理貨後預計出貨至 [ 案場 ] 使用。' }],
    package: [
      { title: '寄件打包', text: '寄件包裝，打包內容： [ 充電器 / 角鋼 / 氣象站腳架 ]。' },
      {
        title: '場佈打包',
        text: '現場場佈打包，準備帶去 [ 地點 ]。打包內容：\n',
      },
    ],
    ship: [
      { title: '已寄送', text: '已寄送，寄貨方式：[ 順豐收件 / 郵局 / 黑貓 ]，包裹單號：[ ]。' },
      { title: '場佈行李', text: '已整理要帶去 [ 地點 ] 進行 [ 安裝 / 更換 ]。' },
    ],
    activate: [
      { title: '啟動', text: '進行啟動程序。' },
      { title: '重開機', text: '進行重開機。' },
    ],
    report: [
      { title: '龜蜜回報', text: '發現問題： [ ]。' },
      {
        title: '客戶回報',
        text: '[ 地點 / 專案 ] 的 [ 人 ] 發現問題： [ ]，使用 [ Line / Email / 電話 ] 回報。',
      },
    ],
    broken: [{ title: '判斷為故障', text: '經過檢測，判斷應為 [ ] 故障。' }],
    takeback: [{ title: '阿龜維修人員現場帶回', text: '已至 [ 地點 ] 將設備帶回，帶回項目：[]' }],
    sendback: [
      { title: '確定設備需寄回', text: '確認 [ 地點 ] 的設備需寄回，寄回項目：[]' },
      { title: '聯絡-電話', text: '[ 地點 / 專案 ] 的 [ 人 ] ，電話： [ ]。' },
      { title: '聯絡-信箱', text: '[ 地點 / 專案 ] 的 [ 人 ] ，信箱： [ ]。' },
      { title: '聯絡-Line', text: '[ 地點 / 專案 ] 的 [ 人 ] ，Line 名稱： [ ]。' },
      { title: '收件資訊', text: '收件時間： [ ]，地址： [ ]。' },
      {
        title: '設備交換',
        text: '此設備需寄回，另外安排將 [ 設備 ] 寄過去 [ 地點 ] 交換。',
      },
    ],
    recall: [
      {
        title: '聯繫案場人員寄回',
        text: '已請 [ 地點 ] 的 [ 人 ] [ 聯絡方式 ] 將設備寄回至阿龜辦公室，需寄回項目：[]，包裹單號：[]。',
      },
      {
        title: '派快遞收件',
        text: '已請 [ 黑貓/順豐 ] 於 [ 日期 ] 至案場收件，預計 [ 日期 ] 送達辦公室。',
      },
    ],
    withdraw: [{ title: '已收取客戶寄回設備', text: '已收到包裹，包裹內含項目：[]。' }],
    pushqueue: [
      { title: '送亞力維修', text: '經過檢測可能為 [ XX問題 ] 需送回亞力維修，排入待維修清單。' },
      {
        title: '阿龜維修',
        text: '經過檢測可能為 [ XX問題 ] 準備阿龜維修，將排入阿龜的待維修清單。',
      },
    ],
    repair: [
      { title: '寄出維修', text: '寄出給亞力維修，包裹單號：[ ]。' },
      { title: '維修內容', text: '維修內容： [ ]，已維修完成。' },
    ],
    hotfix: [
      { title: '現場維修', text: '到 [ 地點 ] 進行維修。' },
      { title: '維修內容', text: '維修內容： [ ]，已維修完成。' },
    ],
    pend: [
      {
        title: '休息',
        text: '設備在 [ 地點 ] 因為 [ 休耕 / 原因 ] 暫時不使用，設為休息狀態。',
      },
    ],
    terminate: [
      { title: '檢測後拒修，直接棄置', text: '經過檢測決定直接廢棄。' },
      { title: '檢測後拒修，寄回案場', text: '經過檢測決定不維修，將設備寄回原案場後廢棄。' },
      { title: '停止維護', text: '專案已到期，放棄維護、停止收資料。' },
      { title: '設備在案場不收回', text: '買斷的設備，在案場不收回。' },
      { title: '無法維修', text: '判斷為無法維修狀態，故直接棄置。' },
    ],

    // common actions
    warn: [{ title: '數值異常', text: '觀察發現異常項目： [ ]。' }],
    upgrade: [
      { title: '軟體升級', text: '軟體升級為版本：[ v1.0.0 ]。' },
      { title: '韌體升級', text: '韌體升級為版本：[ v1.0.0 ]。' },
      { title: '系統升級', text: '作業系統升級：[ ]。' },
      { title: '硬體升級', text: '升級部件：[ ]。' },
    ],
    record: [
      // investigate
      { title: '等待調查', text: '收到注意提醒，等待釐清發生事情。' },
      { title: '無異常', text: '調查/檢測無異常。' },
      { title: '驗貨異常!', text: '進貨檢查異常，須送回亞力新品處理。' },
      { title: '調查紀錄', text: '調查項目：[]，結果：[ 人為疏失造成... / 設備發生...故障 ]。' },
      { title: '靈異現象', text: '目前查不出原因的靈異現象。' },

      // check issue/bug 調查
      { title: '現場檢查', text: '在 [ 地點 / 專案 ] 進行 [ ] 檢查。' },
      { title: '指示客戶檢查', text: '指導 [ 地點 / 專案 ] 的 [ 人 ] 進行 [ ] 檢查。' },

      { title: '檢測紀錄', text: '檢測項目： [ ]，結果：[ ]。' },
      {
        title: '訊號不良',
        text: '訊號不良： [ 距離太遠 / 金屬遮蔽 / 樹木建物遮蔽 / sim 卡過期 ]。',
      },
      {
        title: '電力不足',
        text: '電力不足： [ 光照不足 / 作物遮蔽 / 電池損壞 / 太陽能板太髒 / 潮濕導致電力不穩 ]。',
      },
      { title: '部件損壞', text: '部件損壞： [ 電線斷掉 / 機具打壞 / 零件遺失 ]。' },

      // repair
      { title: '維修紀錄', text: '維修項目：[ ]' },
      {
        title: '閘道器進貨檢查',
        text: '進貨檢查完成：\n☐☑ 配件完整\n☐☑ 外觀正常無異音\n☐☑ 完成接電開機\n☐☑ HDMI 輸出正常\n☐☑ 無線網路\n☐☑ 有線網路\n☐☑ RAM 及 ROM 容量',
      },
    ],
    functest: [
      { title: '感測元件測試', text: '感測元件測試，結果：[ 無法讀取數值 / 數值為亂碼 ]' },
      { title: '開機測試', text: '開起電源測試，結果：[ 正常開機 / 發生錯誤中斷 / 無法過電 ]。' },
      {
        title: 'LoRa 傳輸單元測試',
        text: '✔✘ 1. 接電開機測試\n✔✘ 2. 電池電壓檢測\n✔✘ 3. 外部電力接口測試\n✔✘ 4. 感測器接口測試\n✔✘ 5. LoRa 傳輸測試',
      },
      {
        title: '4G 傳輸單元測試',
        text: '✔✘ 1. 接電開機測試\n✔✘ 2. 電池電壓檢測\n✔✘ 3. 外部電力接口測試\n✔✘ 4. 感測器接口測試\n✔✘ 5. 4G 傳輸測試',
      },
      {
        title: 'CatM 傳輸單元測試',
        text: '✔✘ 1. 接電開機測試\n✔✘ 2. 電池電壓檢測\n✔✘ 3. 外部電力接口測試\n✔✘ 4. 感測器接口測試\n✔✘ 5. CatM 傳輸測試',
      },
      {
        title: '閘道器測試',
        text: '✔✘ 1. 接電開機測試\n✔✘ 2. 反向連結測試\n✔✘ 3. LoRa 啓動測試\n✔✘ 4. 無線網路測試\n✔✘ 5. 有線網路測試',
      },
      {
        title: '縮時攝影測試',
        text: '✔✘ 1. 接電開機測試\n✔✘ 2. 電池電壓檢測\n✔✘ 3. 外部電力接口測試\n✔✘ 4. PiZero 測試\n✔✘ 5. 4G Dongle 測試',
      },
      { title: '路由器測試', text: '✔✘ 1. 接電開機測試\n✔✘ 2. SIM 卡測試\n✔✘ 3. 有線網路接口測試\n✔✘ 4. 無線網路測試' },
      { title: '太陽能板測試', text: '光照測試輸出電壓，[ 無電壓 / 電壓過低 ]，推測原因：[ ]。' },
      { title: '測試錯誤記錄', text: '測試不成功，推測原因：[ ]。' },
      {
        title: '測試環境',
        text: '測試環境：在 [ 地點 ] 進行測試，使用 [ 4G / LoRa / nbioT / 辦公室 ] 網路。',
      },
      { title: '測試方法', text: '測試方法： [ ]。' },
    ],
    other: [],
  },
};

// getters
const getters = {
  map_Action_TO_Status: (state) => {
    const _mapping = {};
    state.mapping_action_to_status.forEach((_map) => {
      _mapping[_map[0]] = _map[1];
    });
    return _mapping;
  },

  map_Status_TO_Action: (state) => {
    const _mapping = {};
    state.mapping_action_to_status.forEach((_map) => {
      _mapping[_map[1]] = _map[0];
    });
    return _mapping;
  },
};

// actions
const actions = {
  get_Record_Data(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Record(settings.params)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.record) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.record.record_time = DateTools.dateTimeZone(
                resData.record.record_time,
                _timezone,
                'YYYY-MM-DD HH:mm',
              );
              resData.record.updated_at = DateTools.dateTimeZone(
                resData.record.updated_at,
                _timezone,
                'YYYY-MM-DD HH:mm',
              );
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Device_Last_Records(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Records(settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

            settings.devices.forEach((_hashid) => {
              if (resData[_hashid]) {
                resData[_hashid].forEach((_record) => {
                  _record.record_time = DateTools.dateTimeZone(
                    _record.record_time,
                    _timezone,
                    'YYYY-MM-DD',
                  );
                  _record.updated_at = DateTools.dateTimeZone(
                    _record.updated_at,
                    _timezone,
                    'YYYY-MM-DD HH:mm',
                  );
                });
              }
            });
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Records(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Records(settings.query)
        .then((res) => {
          const resData = res.data;
          const _user_ids = [];

          if (resData) {
            if (resData.records) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.records.forEach((_record) => {
                if (_user_ids.indexOf(_record.executor) === -1) _user_ids.push(_record.executor);
                if (_user_ids.indexOf(_record.recorder) === -1) _user_ids.push(_record.recorder);

                _record.record_time = DateTools.dateTimeZone(
                  _record.record_time,
                  _timezone,
                  'YYYY-MM-DD HH:mm',
                );
                _record.updated_at = DateTools.dateTimeZone(
                  _record.updated_at,
                  _timezone,
                  'YYYY-MM-DD HH:mm',
                );
              });
            }
          }

          // 設定使用者資訊：呼叫 User API 後才返還 res
          if (_user_ids.length > 0) {
            const _query = {};
            _query.ids = _user_ids.join(',');

            this.dispatch('user/get_Users', { query: _query })
              .then((r) => {
                // 把使用者資料填回去
                const _map_users = this.state.user.map_users; // 取得其他 namespace 的 state
                resData.records.forEach((_record) => {
                  if (_map_users[_record.executor] !== undefined) _record.executor_data = _map_users[_record.executor];
                  if (_map_users[_record.recorder] !== undefined) _record.recorder_data = _map_users[_record.recorder];
                });

                resolve(resData);
              })
              .catch((e) => {
                resolve(resData);
              });
          } else resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
