import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

export const api_Get_Records = (query = undefined) => request('get', `/records${setQueryString(query)}`);

export const api_Get_Record = (params) => request('get', `/records/${params.recordID}`);
export const api_Create_Record = (data) => request('post', '/records', data);

export const api_Store_Record_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/records/${params.recordID}/pic`, _formData, _config);
};

export const api_Update_Record = (params, data) => request('put', `/records/${params.recordID}`, data);
export const api_Delete_Record = (params) => request('delete', `/records/${params.recordID}`);
export const api_Delete_Record_Pic = (params) => request('delete', `/records/${params.recordID}/pic`);

/** --------------------
 *  Batch
 *  ----------------- */

// Batch Records
export const api_Batch_Create_Records = (data, query = undefined) => request('post', `/batchrecords${setQueryString(query)}`, data);

// Batch Records' Pic
export const api_Batch_Store_Records_Pic = (data, query = undefined) => {
  let _queryString = '';
  if (query) _queryString = setQueryString(query); // EX: record_ids=13,14,15

  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/batchrecords/pic${_queryString}`, _formData, _config);
};
